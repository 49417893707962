import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "throttle-function-calls-in-javascript",
  "date": "2015-11-25",
  "title": "THROTTLE FUNCTION CALLS IN JAVASCRIPT",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "If you have written a search component that returns matching result as you type – you would realise that sending a request to the server on every key press is not required as that can result in burdening the server with too many requests."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A good practice is to throttle your requests to fire on a given amount time. Most users these days can type there name in 1/10 of a second, so 100-250ms is a good amount of delay before sending a request.`}</p>
    <h2>{`Debounce`}</h2>
    <p>{`A JavaScript function written to throttle execution is called a `}<strong parentName="p">{`debounce`}</strong>{` function. Or in another words, it limits the rate at which a function can fire.`}</p>
    <h2>{`Coding a debounce function`}</h2>
    <p>{`Let's start by creating an `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` and adding an `}<inlineCode parentName="p">{`input`}</inlineCode>{` field to it for search input.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <title>Throttle function calls</title>
</head>
<body>
  <form>
    <label>Search</label>
    <input type="text" id="search" placeholder="search">
  </form>
</body>
</html>
`}</code></pre>
    <p>{`Create a JavaScript file for our `}<strong parentName="p">{`debounce`}</strong>{` function, let's name it `}<inlineCode parentName="p">{`throttle.js`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function debounce(fn, delay) {
  var timer = null,
    delay = delay || 250;

    return function () {
        var args = arguments;

        clearTimeout(timer);

        timer = setTimeout(function() {
            fn.apply(this, args);
        }.bind(this), delay);
    };
}
`}</code></pre>
    <p>{`We can call this function inside an event handler.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var searchBox = document.getElementById('search');

searchBox.onkeyup = debounce(function(event) {
    console.log('searching .. ' + searchBox.value);
  },
  1000
);
`}</code></pre>
    <p>{`The returned function will be able to reference `}<inlineCode parentName="p">{`timer`}</inlineCode>{` due to closure. Each call to the returned function will share this common timer.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var timer = null,
`}</code></pre>
    <p>{`Calling debounce returns a new anonymous function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`return function () {
`}</code></pre>
    <p><inlineCode parentName="p">{`clearTimeout`}</inlineCode>{` is the basic debounce behaviour where you can call this function several times, but it will only execute once before or after imposing a delay.
Each time the returned function is called, the timer starts over.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`clearTimeout(timeout);
`}</code></pre>
    <p>{`Now you can call the original function with `}<inlineCode parentName="p">{`apply`}</inlineCode>{`. Apply lets you define the `}<inlineCode parentName="p">{`this`}</inlineCode>{` object as well as the arguments (both captured before setTimeout).`}</p>
    <p>{`The source code for this post can be found on `}<a parentName="p" {...{
        "href": "https://github.com/szaranger/throttle-function-calls"
      }}>{`Github`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      